<template>
	<v-container :fluid="$vuetify.breakpoint.name !== 'xl'">
		<!-- intestazione di pagina -->
		<v-row no-gutters class="mb-2">
			<v-col cols="12" :sm="description || showButtons ? 8 : 12">
				<div class="d-flex align-center">
					<div v-if="goback">
						<v-btn color="primary" text @click="$router.go(-1)">
							<v-icon>mdi-arrow-left</v-icon> indietro
						</v-btn>
					</div>
					<div class="mr-4">
						<!-- icone standard (icon viene definita direttamente mentre menuIcon viene inferita dalla struttura del menu e infine admin visualizza la settings standard) -->
						<v-icon v-if="admin || menuIcon || icon" large :color="admin ? 'warning' : ''">
							{{ admin ? 'mdi-cog' : (menuIcon ? menuIcon : icon) }}
						</v-icon>
						<!-- in alternativa può essere visualizzato anche l'avatar -->
						<v-avatar v-if="avatarImage || avatarName" :size="40">
							<img v-if="avatarImage" alt="Avatar" :src="imagesPathPerson + avatarImage + '?width=80&height=80&mode=crop&anchor=top'">
							<avatar v-else :username="avatarName" :size="40" />
						</v-avatar>
					</div>
					<div><h1><span class="grey--text text--lighten-1">{{ admin ? 'admin / ' : '' }}{{ notranslate ? title : gTranslate(title, {key:'page-title'}) }}</span></h1></div>
				</div>
			</v-col>
			<v-col v-if="description || showButtons" cols="12" sm="4" align-self="end">
				<div v-if="description" class="descriptionBlock">
					<h6 class="grey--text text--darken-2">
						<translated-text res="page-description">{{ description }}</translated-text>
					</h6>
				</div>
				<div v-if="showButtons">
					<slot name="buttons" />
				</div>
			</v-col>
		</v-row>
		<!-- blocco todo -->
		<v-row v-if="todo">
			<v-col cols="12">
				<v-alert prominent text border="left" :type="viewDebugElements ? 'error' : 'info'">
					Questa pagina è ancora in fase di sviluppo.<br>
					<span v-if="viewDebugElements">Stai utilizzando la modalità debug: potrebbe non funzionare nulla!</span>
				</v-alert>
			</v-col>
		</v-row>
		<template v-if="!todo || viewDebugElements">
			<!-- SLOT per header -->
			<slot name="header" />
			<!-- SLOT PRIMARIO in modalità nocard = false (default) -->
			<v-card v-if="!nocard">
				<v-card-title v-if="cardTitle">
					<translated-text res="page-subtitle">{{ cardTitle }}</translated-text>
				</v-card-title>
				<v-card-text>
					<slot />
				</v-card-text>			
			</v-card>
			<!-- SLOT PRIMARIO in modalità nocard = true -->
			<div v-if="nocard">
				<slot />
			</div>
			<!-- SLOT per il footer -->
			<slot name="footer" />
		</template>
		<!-- SLOT per il debug -->
		<slot v-if="viewDebugElements" name="debug" />
	</v-container>
</template>


<script>
import Avatar from 'vue-avatar'
// ***********************************************************************************
// ** componente "container" standard con titoloe box
// ***********************************************************************************
// nasce con lo scopo di uniformare le varie pagine 
export default {
	name: 'StandardContainerComponent',
	components: { Avatar },
	props: {
		// 
		title: {
			type: String,
			default: () => {return ''}
		},
		// icona specifica (nome completo di una ico mdi- )
		icon: {
			type: String,
			default: () => {return ''}
		},
		// descrizione supplmentare (todo: collegare la sistema di menu ? )
		description: {
			type: String,
			default: () => {return ''}
		},
		// attiva il prefisso admin (appare davanti al titolo come "admin / ") e l'icona standard (ingranaggio di setup)
		admin: {
			type: Boolean,
			default: () => {return false}
		},
		// se true non attiva il sistema di traduzione del titolo (usato quando il titolo è dinamico come nel caso della pagina persona)
		notranslate: {
			type: Boolean,
			default: () => {return false}
		},
		// attiva il banner di todo al posto dei contenuti dello slot
		todo: {
			type: Boolean,
			default: () => {return false}
		},
		// carica l'icona indicata nel sistema di menu
		menuicon: {
			type: String,
			default: () => {return ''}
		},
		// visualizza il pulsante per l'history back
		goback: {
			type: Boolean,
			default: () => {return false}
		},
		// da usare al posto dell'icona: visualizza l'avatar con immagine
		avatarImage: {
			type: String,
			default: () => {return ''}
		},
		// da usare al posto dell'icona: visualizza l'avatar con nome
		avatarName: {
			type: String,
			default: () => {return ''}
		},
		// attiva il blocco cart-title
		cardTitle: {
			type: String,
			default: () => {return ''}
		},
		// visualizza lo slot primario senza la card attorno
		nocard: {
			type: Boolean,
			default: () => {return false}
		},		  
		// visualizza lo slot a dx del titolo e destinato ai pulsanti
		showButtons: {
			type: Boolean,
			default: () => {return false}
		},		  
	},
	computed: {
		appMenu() { return this.$store.getters['appMenu'] },
		viewDebugElements() { return this.$store.getters['viewDebugElements'] },
		imagesPathPerson() { return this.$store.getters['imagesPathPerson'] },
		menuIcon() {
			if(this.menuicon) {
				var menuItem = this.appMenu.filter((item) => {
					return item.Name === this.menuicon
				})
				if(menuItem) {
					return menuItem[0].Icon
				} else return ''
			} else return ''
		}
	}
}
</script>

<style scoped lang="less">
.headerBlock{
	position: relative;
	height: 45px;
	margin-bottom: 10px;
	.iconBlock {
		position:absolute;
		display: inline-block;
		width: 45px;
		height: 40px;
		margin-top: 5px;
	}
	.titleBlock {
		position:absolute;
		left: 45px;
		display: inline-block;
		height: 45px;
		white-space: nowrap;
	}
}
.descriptionBlock {
	min-width: 200px;
	height: 30px;
	margin-bottom: 10px;
	padding:0;
	display: flex;
	justify-content: flex-end;
    align-items: flex-end;
	@media (max-width: 600px) {
		display: none;
	}
}
</style>
